body {
  margin: 0;
  padding: 0;
  background-color: #5b4ae4;
  font-family: /*'Lustria',*/ sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}
