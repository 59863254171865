body {
  margin: 0;
  padding: 0;
  background-color: #5b4ae4;
  font-family: /*'Lustria',*/ sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

:root {
  --blocks-scale: 1;
}


@media (max-width: 300px) {
  :root {
    --blocks-scale: .3;
  }
}
@media (max-width: 400px) {
  :root {
    --blocks-scale: .4;
  }
}
@media (max-width: 600px) {
  :root {
    --blocks-scale: .6;
  }
}

.App {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

/* hack to rotate image... grossss */
.App:before {
  position: absolute;
  content: "";
  float: left;
  width: 100vw;
  height: 100vh;
  background: url(/static/media/background-edit.d9bc4f8c.png) no-repeat 0 0;
}
@media (max-width: 500px) {
  .App:before {
    width: 100vh;
    height: 100vw;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}

.container {
  display: flex;
  min-width: 900px;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}
@media (max-width: 900px) {
  .container {
    min-width: 100%;
  }
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.8em;
  color: white;
}
@media (max-width: 450px) {
  h1 {
    padding: 8px;
    font-size: 1.6em;
  }
}
@media (max-width: 400px) {
  h1 {
    /* padding: 8px; */
    font-size: 1.4em;
  }
}
@media (max-width: 310px) {
  h1 {
    font-size: 1em;
  }
}


.spinning-question-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 25vh;
}

.spinning-question {
  padding: 4px 50px;
  max-width: 100vh;
  font-size: 1.4em;
}
@media (max-height: 600px) {
  .spinning-question {
    font-size: 1.2em;
  }
}
@media (max-width: 400px) {
  .spinning-question {
    padding: 4px;
  }
}

.inner {
  min-width: 600px;
  min-height: 100px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: 600;
  color: white;
}
@media (max-width: 600px) {
  .inner {
    text-align: center;
    min-width: 0;
    min-width: initial;
  }
}

.reroll-btn {
  min-height: 60px;
  border-width: 1px;
  border-style: none;
  border-radius: 10px;
  padding: 15px 35px;
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 1.5em;
  color: white;
  background: rgb(252,60,73);
  background: linear-gradient(0deg, rgba(252,60,73,1) 0%, rgba(255,110,59,1) 100%);
  /* box-shadow: 0 7px #999; */
}
@media (max-width: 350px) {
  .reroll-btn {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.reroll-btn:focus {
  outline: none;
}

.reroll-btn:active {
  background: linear-gradient(0deg, rgba(252,60,73,1) 0%, rgb(195, 81, 41) 100%);
}

/* .reroll-btn:active {
  box-shadow: 0 3px #666;
  transform: translateY(4px);
} */

.bottom-left-blocks,
.bottom-right-blocks,
.top-right-blocks {
  position: absolute;
  background-size: cover;
}

.bottom-left-blocks {
  bottom: 0;
  left: 0;
  width: calc(198px * 1);
  width: calc(198px * var(--blocks-scale));
  height: calc(397px * 1);
  height: calc(397px * var(--blocks-scale));
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMYAAAGNCAYAAABKcw5GAAAE3klEQVR4nO3coY5VVxiG4bXPLCRJCQpXxxXUIbA13AMCx5Wg8dxDDZKKutoKHBIDgiAgmI0hIeG8QSC6lnieK/iSmTf733NO5jj/fHQOxvHyr2P1hv/Tefqx/8xl9QDYkTAgCAOCMCAIA4IwIAgDgjAgCAOCMCAIA4IwIAgDgjAgCAOCMCAIA4IwIAgDgjAgCAOCMCAIA4IwIAgDgjAgCAOCMCAIA4IwIAgDgjAgCAOCMCAIA4IwIAgDgjAgCAOCMCAIA4IwIAgDgjAgCAOCMCAIA4IwIAgDgjAgCAOCMCAIA4IwIAgDgjAgCAOCMCAIA4IwIAgDgjAgCAOCMCAIA8IcY9xZPQJ2c6wewBrnea6esDWnFARhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhAAD8smO8+Ofh6hFbePzg79UT2MccY7xaPWITx+oB7MPLNwRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAmMc53q4esYNz9QC2Mu+//3Jv9YgdvF49gK04pSAIA4IwIAgDgjAgCAOCMCAIA4IwIAgDgjAgCAOCMCAIA4IwIAgDgjAgCAOCMCAIA4IwIAgDgjAgCAOCMCAIA4IwIAgDgjAgCAOCMCDM1QN2cT794/fVG3bw37vPqydsQRjfvVk9gH04pSAIA4IwIAgDgjAgCAOCMCAIA4IwIAgDgjAgCAOCMCAIA4IwIAgDgjAgCAOCMCAIA4IwIAgDgjAgCAOCMCAIA4IwIAgDgjAgCAOCMCDMyzg/rh6xidurB7CP+ezTE78QY4wx7q4ewEbm5ThWb4DtzIu3DLgyD08MuDJvdAFX5uGUgivzxikFV+ZFF3DFX6Ug+BwDgicGBGFAmJfhlIIfeWJAEAYE35WC4IkBwSffEHzAB8EpBcEpBcEpBWHeOKXgis8xIHhiQPDyDcHLNwT/JQSCUwqCUwqCr4RAcEpBcEp992H1APbhlPrmeP7vb6s3sA9ZQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGBGFAEAYEYUAQBgRhQBAGhHnr3ly9AbbjiQFBGBCEAUEYEIQBQRgQhAFBGBCEAUEYEIQBQRgQhAFBGBCEAUEYEIQBQRgQhAFBGBCEAUEYEIQBQRgQhAFBGBCEAUEYEIQBQRgQhAFBGBCEAUEYEIQBQRgQhAFBGBCEAUEYEIQBQRgQhAFBGBCEAUEYEIQBQRgQhAFBGBCEAUEYEIQBQRgQhAFBGBCEAUEYEIQBQRgQhAFBGBCEAUEYEIQB4SvK5SN00k3pewAAAABJRU5ErkJggg==);
}

.bottom-right-blocks {
  bottom: 0;
  right: 0;
  width: calc(66px * 1);
  width: calc(66px * var(--blocks-scale));
  height: calc(198px * 1);
  height: calc(198px * var(--blocks-scale));
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAADGCAYAAABmdVYmAAABGElEQVR4nO3cQQ2AMAAEwYInPphEBYoQBJ/10IbMKLisgNvGfb2Dsc8esAohIkSEiBARIkJEiAgRISJEhIgQESJCRIgIESEiRISIEBEiQkSICBEhIkSEiBARIkJEiAgRISJEhIgQESJCRIgIESEiRISIEBEiQkSICBEhIkSEiBARIkJEiAgRISJEhIgQESJCRIgIAQAAAAAAAAAAAAAAAAAAAAD80PYc5zt7xAp8zESICBEhIkSEiBARIkJEiAgRISJEhIgQESJCRIgIESEiRISIEBEiQkSICBEhIkSEiBARIkJEiAgRISJEhIgQESJCRIgIESEiRISIEBEiQkSICBEhIkSEiBARIkJEiAgRISJEhIgQESJC5APNvgV7WXvpKQAAAABJRU5ErkJggg==);
}

.top-right-blocks {
  top: 0;
  right: 0;
  width: calc(329px * 1);
  width: calc(329px * var(--blocks-scale));
  height: calc(296px * 1);
  height: calc(296px * var(--blocks-scale));
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUkAAAEoCAYAAADVIgNXAAAE1klEQVR4nO3XP4rdZRiG4e+MJyFaiIUWppNpxCbVNC5AXIONrYW4CYsUFq5CC8ElKFhYpRkQweY0MoUgjILxT2bi+bkAw92+B+a6VvDwwXfDu3v7s2+3dcc9vX92uHp1fz69Y9rjl79+8smDby6md0zbv/7Sk9293Z1/h3uPHx7Ws7M7/y/OpgcAnDKRBAgiCRBEEiCIJEAQSYAgkgBBJAGCSAIEkQQIIgkQRBIgiCRAEEmAIJIAQSQBgkgCBJEECCIJEEQSIIgkQBBJgCCSAEEkAYJIAgSRBAgiCRBEEiCIJEAQSYAgkgBhPz0AOE1Pt9vft5vdYXrHNJEEXujn6z9eO/6zzqd3THNuAwSRBAgiCRBEEiCIJEAQSYAgkgBBJAGCSAIEkQQIIgkQRBIgiCRAEEmAIJIAQSQBgkgCBJEECCIJEEQSIIgkQBBJgCCSAEEkAYJIAgSRBAgiCRBEEiCIJEAQSYAgkgBhv9b6bnrEtN22fltrXU3vmPb3un+11vprese44/IOa611XG9OTzgFu+kBwGn64eLdy7XWo+kd05zbAEEkAYJIAgSRBAgiCRBEEiCIJEAQSYAgkgBBJAGCSAIEkQQIIgkQRBIgiCRAEEmAIJIAQSQBgkgCBJEECCIJEEQSIIgkQBBJgCCSAEEkAYJIAgSRBAgiCRBEEiCIJEDYTw+Ak/PF5++ttd6ZnjHt0+9//OXBze3N9I5pIgn/98Fa68PpEdO+euvhYW3b+fSOac5tgCCSAEEkAYJIAgSRBAgiCRBEEiCIJEAQSYAgkgBBJAGCSAIEkQQIIgkQRBIgiCRAEEmAIJIAQSQBgkgCBJEECCIJEEQSIIgkQBBJgCCSAEEkAYJIAgSRBAgiCRBEEiCIJEAQSYAgkgBBJAGCSAIEkQQIIgkQRBIgiCRAEEmAIJIAQSQBgkgCBJEECCIJEEQSIIgkQBBJgCCSAEEkAYJIAgSRBAgiCRBEEiCIJEAQSYAgkgBBJAGCSAIEkQQIIgkQRBIgiCRA2E8PgBP05VrrcnrEtI/+vHr/lX9vr6d3TNtNDwBO0/bxxeVa69H0jmnObYAgkgBBJAGCSAIEkQQIIgkQRBIgiCRAEEmAIJIAQSQBgkgCBJEECCIJEEQSIIgkQBBJgCCSAEEkAYJIAgSRBAgiCRBEEiCIJEAQSYAgkgBBJAGCSAIEkQQIIgkQRBIgiCRAEEmAIJIAQSQBgkgCBJEECCIJEEQSIIgkQBBJgCCSAEEkAYJIAgSRBAgiCRBEEiCIJEAQSYAgkgBBJAGCSAIEkQQIIgkQRBIgiCRAEEmAIJIAQSQBgkgCBJEECCIJEEQSIIgkQNhPDwBO00/Xz349btthesc0kQRe6Plxe2OtdT69Y5pzGyCIJEAQSYAgkgBBJAGCSAIEkQQIIgkQRBIgiCRAEEmAIJIAQSQBgkgCBJEECCIJEEQSIIgkQBBJgCCSAEEkAYJIAgSRBAgiCRBEEiCIJEAQSYAgkgBBJAGCSAIEkQQIIgkQRBIgiCRAEEmAIJIAQSQBgkgCBJEECCIJEEQSIIgkQBBJgCCSAEEkAYJIAgSRBAgiCRBEEiCIJEAQSYAgkgBBJAGCSAIEkQQIIgkQRBIgiCRAEEmAIJIAQSQBgkgCBJEECCIJEP4DITlHrQLJpIQAAAAASUVORK5CYII=);
}

.animate {
  -webkit-animation-duration: 1.3s;
          animation-duration: 1.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}


.flipInX {
  -webkit-animation-name: flipInX;
          animation-name: flipInX;
}

/* https://daneden.github.io/animate.css/ */
@-webkit-keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotateX(90deg);
                transform: perspective(400px) rotateX(90deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }

    10% {
        -webkit-transform: perspective(400px) rotateX(0deg);
                transform: perspective(400px) rotateX(0deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }

    20% {
        -webkit-transform: perspective(400px) rotateX(-90deg);
                transform: perspective(400px) rotateX(-90deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }

    30% {
        -webkit-transform: perspective(400px) rotateX(-180deg);
                transform: perspective(400px) rotateX(-180deg);
    }

    40% {
        -webkit-transform: perspective(400px) rotateX(-270deg);
                transform: perspective(400px) rotateX(-270deg);
    }

    50% {
        -webkit-transform: perspective(400px) rotateX(-360deg);
                transform: perspective(400px) rotateX(-360deg);
    }

    /* 60% {
        transform: perspective(400px) rotateX(-450deg);
        animation-timing-function: ease-in
    }

    70% {
        transform: perspective(400px) rotateX(-405deg);
        animation-timing-function: ease-in
    } */

    80% {
        -webkit-transform: rotateX(-360deg);
                transform: rotateX(-360deg);
    }

    90% {
        -webkit-transform: rotateX(-360deg);
                transform: rotateX(-360deg);
    }

    to {
        -webkit-transform: perspective(400px);
                transform: perspective(400px)
    }
}
@keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotateX(90deg);
                transform: perspective(400px) rotateX(90deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }

    10% {
        -webkit-transform: perspective(400px) rotateX(0deg);
                transform: perspective(400px) rotateX(0deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }

    20% {
        -webkit-transform: perspective(400px) rotateX(-90deg);
                transform: perspective(400px) rotateX(-90deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }

    30% {
        -webkit-transform: perspective(400px) rotateX(-180deg);
                transform: perspective(400px) rotateX(-180deg);
    }

    40% {
        -webkit-transform: perspective(400px) rotateX(-270deg);
                transform: perspective(400px) rotateX(-270deg);
    }

    50% {
        -webkit-transform: perspective(400px) rotateX(-360deg);
                transform: perspective(400px) rotateX(-360deg);
    }

    /* 60% {
        transform: perspective(400px) rotateX(-450deg);
        animation-timing-function: ease-in
    }

    70% {
        transform: perspective(400px) rotateX(-405deg);
        animation-timing-function: ease-in
    } */

    80% {
        -webkit-transform: rotateX(-360deg);
                transform: rotateX(-360deg);
    }

    90% {
        -webkit-transform: rotateX(-360deg);
                transform: rotateX(-360deg);
    }

    to {
        -webkit-transform: perspective(400px);
                transform: perspective(400px)
    }
}



