:root {
  --blocks-scale: 1;
}


@media (max-width: 300px) {
  :root {
    --blocks-scale: .3;
  }
}
@media (max-width: 400px) {
  :root {
    --blocks-scale: .4;
  }
}
@media (max-width: 600px) {
  :root {
    --blocks-scale: .6;
  }
}

.App {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

/* hack to rotate image... grossss */
.App:before {
  position: absolute;
  content: "";
  float: left;
  width: 100vw;
  height: 100vh;
  background: url('./images/background-edit.png') no-repeat 0 0;
}
@media (max-width: 500px) {
  .App:before {
    width: 100vh;
    height: 100vw;
    transform: rotate(90deg);
  }
}

.container {
  display: flex;
  min-width: 900px;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}
@media (max-width: 900px) {
  .container {
    min-width: 100%;
  }
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.8em;
  color: white;
}
@media (max-width: 450px) {
  h1 {
    padding: 8px;
    font-size: 1.6em;
  }
}
@media (max-width: 400px) {
  h1 {
    /* padding: 8px; */
    font-size: 1.4em;
  }
}
@media (max-width: 310px) {
  h1 {
    font-size: 1em;
  }
}


.spinning-question-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 25vh;
}

.spinning-question {
  padding: 4px 50px;
  max-width: 100vh;
  font-size: 1.4em;
}
@media (max-height: 600px) {
  .spinning-question {
    font-size: 1.2em;
  }
}
@media (max-width: 400px) {
  .spinning-question {
    padding: 4px;
  }
}

.inner {
  min-width: 600px;
  min-height: 100px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: 600;
  color: white;
}
@media (max-width: 600px) {
  .inner {
    text-align: center;
    min-width: initial;
  }
}

.reroll-btn {
  min-height: 60px;
  border-width: 1px;
  border-style: none;
  border-radius: 10px;
  padding: 15px 35px;
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 1.5em;
  color: white;
  background: rgb(252,60,73);
  background: linear-gradient(0deg, rgba(252,60,73,1) 0%, rgba(255,110,59,1) 100%);
  /* box-shadow: 0 7px #999; */
}
@media (max-width: 350px) {
  .reroll-btn {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.reroll-btn:focus {
  outline: none;
}

.reroll-btn:active {
  background: linear-gradient(0deg, rgba(252,60,73,1) 0%, rgb(195, 81, 41) 100%);
}

/* .reroll-btn:active {
  box-shadow: 0 3px #666;
  transform: translateY(4px);
} */

.bottom-left-blocks,
.bottom-right-blocks,
.top-right-blocks {
  position: absolute;
  background-size: cover;
}

.bottom-left-blocks {
  bottom: 0;
  left: 0;
  width: calc(198px * var(--blocks-scale));
  height: calc(397px * var(--blocks-scale));
  background-image: url('./images/bottom-left.png');
}

.bottom-right-blocks {
  bottom: 0;
  right: 0;
  width: calc(66px * var(--blocks-scale));
  height: calc(198px * var(--blocks-scale));
  background-image: url('./images/bottom-right.png');
}

.top-right-blocks {
  top: 0;
  right: 0;
  width: calc(329px * var(--blocks-scale));
  height: calc(296px * var(--blocks-scale));
  background-image: url('./images/top-right.png');
}
